import * as React from 'react';
import { useSelector } from 'react-redux';

import { ntc } from 'src/explore/services/currency_helper';
import NumberShortener from 'src/explore/services/NumberShortener';

import { Details } from './elements/Details';
import { Identification } from './elements/Identification';

interface Props {
  onNavigation: () => void;
}

export const Account: React.FC<Props> = ({ onNavigation }) => {
  const account = useSelector(( state: any ) => state.data.account );

  // FIXME: loading or error
  if ( account.pristine ) return null;

  if ( account.data ) {
    return (
      <Details
        credit={
          ntc( account.data.eligible_credits_balance_in_cents, {
            verbose: true,
            precision: 0,
          }) as string
        }
        name={account.data.first_name || 'Food Explorer'}
        points={NumberShortener.shorten( account.data.reward_points, {
          min: 10000,
        })}
        onNavigation={onNavigation}
      />
    );
  }

  return <Identification />;
};
