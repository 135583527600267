import React, { FC } from 'react';

import { useNavigationMenus } from 'src/explore/hooks/useNavigationMenus';

import { Links } from '../../../../../Links';

import styles from './index.module.sass';

interface HelpProps {
  onClick: () => void;
}

const Help: FC<HelpProps> = ({ onClick }) => {
  const { helpMenu } = useNavigationMenus();

  return (
    <Links
      containerClass={`${styles.container} pt-8 pl-4 mt-8 mr-n5`}
      data-testid="help"
      itemClass={`${styles.item} pr-5`}
      links={helpMenu.children[1].children}
      onNavigation={onClick}
    />
  );
};

export default Help;
