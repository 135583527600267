import * as React from 'react';
import cls from 'classnames';

import { INavItem } from 'src/explore/types/shoppe';

import { LazyImgix } from 'src/explore/elements/LazyImgix';
import { NavButton } from 'src/explore/elements/NavButton';
import { NavLink } from 'src/explore/elements/NavLink';
import SvgIcon from 'src/explore/elements/SvgIcon';

import styles from './index.module.sass';

interface Props {
  includeDesktopStyle?: boolean;
  navItem: INavItem;
  open: boolean;
  scrollOffset: number;
  title: string;
  onClickLink: () => void;
  onClickBack: () => void;
  onClickSubgroup: () => void;
  onMouseEnterSubgroup: () => void;
  onMouseLeaveSubgroup: () => void;
}

export const Subgroup: React.FC<Props> = ({
  includeDesktopStyle,
  navItem,
  open,
  scrollOffset,
  title,
  onClickBack,
  onClickLink,
  onClickSubgroup,
  onMouseEnterSubgroup,
  onMouseLeaveSubgroup,
}) => (
  <>
    <NavButton
      className={cls( styles.button, 'position-relative d-flex justify-content-between pr-6 w-100', {
        [`${styles.desktop} py-xl-4 pr-xl-0`]: includeDesktopStyle,
        [styles.selected]: open,
      })}
      id={`fs__${navItem.id}`}
      onClick={onClickSubgroup}
    >
      <span>{title}</span>
      <SvgIcon
        baseline={false}
        icon="icon-arrow-right"
        iconClasses="fs-3"
        svgClasses={cls({ 'd-xl-none': includeDesktopStyle })}
      />
    </NavButton>

    <div
      className={cls( styles.group, 'position-absolute w-100 px-5 overflow-auto', {
        [`${styles.desktop} text-xl-left`]: includeDesktopStyle,
        [styles.on]: open,
      })}
      style={{ top: `${scrollOffset}px` }}
      onMouseEnter={onMouseEnterSubgroup}
      onMouseLeave={onMouseLeaveSubgroup}
    >
      <NavButton
        className={cls( styles.back, 'mt-7 fs-5 text-uppercase', {
          'd-xl-none': includeDesktopStyle,
          [styles.on]: open,
        })}
        onClick={onClickBack}
      >
        <SvgIcon baseline={false} icon="icon-arrow-left" iconClasses="mr-1 fs-3" svgClasses="" />
        <span>Back</span>
      </NavButton>

      <strong
        className={cls( 'fs-9 text-uppercase', {
          'd-xl-none': includeDesktopStyle,
        })}
      >
        {title}
      </strong>

      <div
        className={cls( styles.links, 'pl-4 mt-1 mr-n5', {
          'pt-xl-6 pb-xl-4 pl-xl-0 mt-xl-0 mr-xl-0 mb-xl-1': includeDesktopStyle,
        })}
      >
        <div className={cls( includeDesktopStyle && 'container-xl' )}>
          <div className="row">
            <div
              className={cls( 'col', {
                'pb-xl-4': includeDesktopStyle,
                'col-xl-7 offset-xl-1': navItem.featuredImageUrl && includeDesktopStyle,
              })}
            >
              <ul className="row">
                {navItem.children.map(( link ) => (
                  <li
                    className={cls( styles.item, 'col-12 pt-3 pl-0 mt-3 text-dark', {
                      'pt-xl-6 pl-xl-4 mt-xl-0': includeDesktopStyle,
                      'col-xl-4': navItem.featuredImageUrl && includeDesktopStyle,
                      [styles.noFeature]: !navItem.featuredImageUrl,
                    })}
                    key={link.title}
                  >
                    <NavLink
                      className={cls( styles.link, 'd-block w-100', {
                        'd-xl-inline fs-xl-5': includeDesktopStyle,
                      })}
                      link={link}
                      onClick={onClickLink}
                    >
                      {link.title}
                    </NavLink>
                  </li>
                ))}
              </ul>
            </div>
            {navItem.featuredImageUrl && (
              <div className={cls( 'd-none ', { 'col-xl-4 d-xl-block': includeDesktopStyle })}>
                <NavLink link={navItem} onClick={onClickLink}>
                  <LazyImgix
                    className="w-100 h-auto"
                    src={navItem.featuredImageUrl}
                    alt={navItem.title}
                    imgixParams={{ ar: '1.34:1' }}
                    sizes="40vw"
                  />
                </NavLink>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  </>
);
