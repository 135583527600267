import * as React from 'react';
import cls from 'classnames';

import { INavItem } from 'src/explore/types/shoppe';
import { Overlay } from './elements/Overlay';
import { Switch } from './elements/Switch';
import { Tabs } from './elements/Tabs';
import { Underlay } from './elements/Underlay';

import styles from './index.module.sass';

interface Props {
  className?: string;
  includeDesktopStyle?: boolean;
  isOpen?: boolean;
  primary: INavItem; // root element
  secondary: INavItem;
  onClose: () => void;
  onNavigation: () => void;
}

export const NavMenu: React.FC<Props> = ({
  className,
  includeDesktopStyle = true,
  isOpen,
  primary,
  secondary,
  onClose,
  onNavigation,
}) => {
  const [ showSubgroup, setShowSubgroup ] = React.useState<boolean>( false );
  const [ tab, setTab ] = React.useState<number>( 0 );

  const menuRef = React.useRef();

  return (
    <>
      <div
        className={cls(
          className,
          styles.container,
          'position-absolute pt-7 bg-white text-dark font-weight-bold',
          {
            [`${styles.desktop} pt-xl-0`]: includeDesktopStyle,
            [styles.subgroup]: showSubgroup,
          }
        )}
        ref={menuRef}
      >
        <Switch
          includeDesktopStyle={includeDesktopStyle}
          showSubgroup={showSubgroup}
          tab={tab}
          onClickTab={setTab}
        />

        <Tabs
          includeDesktopStyle={includeDesktopStyle}
          menuRef={menuRef}
          primaryLinks={primary.children}
          secondaryLinks={secondary.children}
          showSubgroup={showSubgroup}
          tab={tab}
          onClickBack={() => setShowSubgroup( false )}
          onClickSubgroup={() => setShowSubgroup(( show ) => !show )}
          onHoverSubgroup={setShowSubgroup}
          onNavigation={onNavigation}
        />
      </div>

      <Underlay includeDesktopStyle={includeDesktopStyle} visible={isOpen} />
      <Overlay includeDesktopStyle={includeDesktopStyle} visible={isOpen} onClick={onClose} />
    </>
  );
};
