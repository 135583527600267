import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import camelCaseKeys from 'camelcase-keys';
import cls from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass } from '@fortawesome/pro-light-svg-icons';
import { Menu, AsyncTypeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';

import { useApiObject } from 'src/explore/hooks/useApiObject';
import { useRoutes } from 'src/explore/hooks/useRoutes';
import api from 'src/explore/services/api';
import BreakpointsService from 'src/explore/services/breakpoints';
import redirectService from 'src/explore/services/redirect';
import URLParams from 'src/explore/services/URLParams';

import { OptionFactory } from './services/OptionFactory';

import styles from './index.module.sass';

type ISearchResult = {
  concept: string;
  permalink?: string;
  displayName: string;
  score: number;
  source?: string;
};

const MAX_SEARCH_QUERY_LENGTH = 500;

export const GlobalSearch = ({
  classNames = {},
  clearOnBlur,
  placeholder,
  onBlur,
  onFocus,
  onInputChange,
}: {
  classNames?: {
    form?: string;
    input?: string;
    submit?: string;
  };
  clearOnBlur?: boolean;
  placeholder?: string;
  onBlur?: ( e: Event ) => void;
  onFocus?: ( e: Event ) => void;
  onInputChange?: ( query: string ) => void;
}) => {
  const [ hasFocus, setHasFocus ] = useState( false );
  const [ loading, setLoading ] = useState( false );
  const [ options, setOptions ] = useState<ISearchResult[]>([]);
  const [ priorScrollY, setPriorScrollY ] = React.useState( window.scrollY );
  const [ pristine, setPristine ] = useState( true );

  const inputRef = useRef<any>();
  const urlParams = useRef<any>( new URLParams()).current; // FIXME: types

  const routes = useRoutes();

  const defaultPlaceholder = BreakpointsService.appliesTo( 'sm' )
    ? 'Search America’s Greatest Foods & Shops'
    : 'Search';
  const defaultQuery = useMemo(() => urlParams.read( 'q' ), [ urlParams ]);

  const { data: suggestedSearches } = useApiObject<ISearchResult[]>( `suggested_searches`, {
    camelCaseKeys: true,
    apiPathOverride: '/api/v3/',
  });
  if ( options.length === 0 && suggestedSearches?.length > 0 ) {
    setOptions(
      suggestedSearches.map(( suggestion ) => ({ ...suggestion, source: 'suggested-search' }))
    );
  }

  // Permalink builder
  const buildPermalink = useCallback(
    ( item: ISearchResult, params: { [key: string]: string } = {}) => {
      let url = '';
      switch ( item.concept ) {
        case 'collections':
          url = routes.collection( item.permalink, params );
          break;
        case 'foods':
          url = routes.food( item.permalink, params );
          break;
        case 'holidays':
          url = routes.holiday( item.permalink, params );
          break;
        case 'shops':
          url = routes.merchant( item.permalink, params );
          break;
        case 'spotlight':
          url = routes.spotlight( item.permalink, params );
          break;
        case 'gbtv':
          url = routes.tv.video( item.permalink, params );
          break;
        default:
          url = `${item.permalink}?${URLParams.buildURLParams( params )}`;
      }
      return url;
    },
    [ routes ]
  );

  const handleBlur = ( e: Event ) => {
    setHasFocus( false );

    if ( onBlur ) {
      onBlur( e );
    }
    if ( clearOnBlur && inputRef.current ) {
      inputRef.current.clear();
    }
  };

  const handleSelect = useCallback(
    ( items: ISearchResult[]) => {
      if ( items.length ) {
        const path = items[0].permalink
          ? buildPermalink( items[0], { ref: items[0].source || 'gs' })
          : routes.search( items[0].displayName, { ref: items[0].source || 'gs' });

        redirectService.redirect( path, items[0].concept === 'gbtv' );
      }
    },
    [ buildPermalink, routes ]
  );

  const handleSubmit = ( query: string ) => {
    inputRef.current.hideMenu();

    redirectService.redirect( routes.search( query ));
  };

  const handleSearch = useCallback(
    ( query: string ) => {
      setPristine( false );

      if ( query ) {
        setLoading( true );

        api
          .post( `https://autosuggest.data.goldbelly.com/autosuggest`, {
            max_results: 100,
            query,
          })
          .then(( response ) => {
            if ( query.trim().length > 0 ) {
              setOptions( camelCaseKeys( response.data, { deep: true }));
            }
          })
          .catch(() => {
            setOptions([]);
          })
          .finally(() => {
            setLoading( false );
          });
      }

      if ( onInputChange ) {
        onInputChange( query );
      }
    },
    [ onInputChange ]
  );

  useEffect(() => {
    const blurOnScrollDown = () => {
      if ( inputRef.current && window.scrollY > priorScrollY ) {
        inputRef.current.blur();
      }

      setPriorScrollY( window.scrollY );
    };
    window.addEventListener( 'scroll', blurOnScrollDown );

    return () => window.removeEventListener( 'scroll', blurOnScrollDown );
  }, [ inputRef, priorScrollY ]);

  return (
    <form
      action={routes.search( '' )}
      className={cls( classNames.form, styles.search, 'rounded' )}
      method="get"
    >
      <AsyncTypeahead
        className="spec__global-search"
        defaultInputValue={defaultQuery}
        filterBy={() => true}
        id="search"
        inputProps={{
          id: 'q',
          className: cls( styles.input, 'w-100 pl-3 pl-sm-4 pr-8 rounded', classNames.input ),
          maxLength: MAX_SEARCH_QUERY_LENGTH,
        }}
        isLoading={loading}
        labelKey="displayName"
        open={hasFocus && pristine ? true : undefined}
        options={options}
        placeholder={placeholder || defaultPlaceholder}
        ref={inputRef}
        renderMenu={( results, menuProps ) => {
          if ( !results.length ) {
            return null;
          }
          return (
            <Menu className={`${styles.menu} w-100`} {...menuProps}>
              {results[0].source === 'suggested-search' && (
                <div className="px-4 py-2 spec__trending-header">
                  <i className={styles.trendingHeader}>Suggested Searches</i>
                </div>
              )}
              {results.map(( option, index ) => (
                <OptionFactory
                  key={`${option.concept}${option.permalink || option.displayName}`}
                  option={option}
                  position={index}
                />
              ))}
            </Menu>
          );
        }}
        onBlur={handleBlur}
        onChange={handleSelect}
        onFocus={( e ) => {
          onFocus?.( e );
          setHasFocus( true );
        }}
        onKeyDown={( e: any ) => {
          if ( e.keyCode === 13 ) {
            handleSubmit( e.currentTarget.value );
          }
        }}
        onSearch={handleSearch}
      />

      <button
        aria-label="Submit Search"
        className={cls(
          styles.button,
          'position-absolute border-0 bg-transparent fs-5',
          classNames.submit
        )}
        type="submit"
        onClick={( e: any ) => {
          if ( inputRef.current ) {
            e.preventDefault();

            const query = inputRef.current.inputNode.value;
            if ( !query ) {
              inputRef.current.focus();
            } else {
              handleSubmit( query );
            }
          }
        }}
      >
        <FontAwesomeIcon icon={faMagnifyingGlass} />
      </button>
    </form>
  );
};
