import * as React from 'react';
import cls from 'classnames';
import { useSelector } from 'react-redux';

import { NavButton } from 'src/explore/elements/NavButton';

import styles from './index.module.sass';

interface Props {
  includeDesktopStyle?: boolean;
  showSubgroup: boolean;
  tab: number;
  onClickTab: ( tab: number ) => void;
}

export const Switch: React.FC<Props> = ({ includeDesktopStyle, showSubgroup, tab, onClickTab }) => {
  const account = useSelector(( state: any ) => state.data.account );

  return (
    <nav
      className={cls( styles.container, 'position-relative d-flex px-5', {
        'd-xl-none': includeDesktopStyle,
        [styles.on]: !showSubgroup,
      })}
    >
      {[ 'Shop By', account.data ? 'My Account' : 'Log In | Sign Up' ].map(( option, i ) => (
        <NavButton
          className={cls( styles.button, 'pb-1 mr-6 fs-5 text-uppercase text-nowrap', {
            'text-secondary': i === tab,
            [styles.on]: i === tab,
          })}
          key={option}
          onClick={() => onClickTab( i )}
        >
          {option}
        </NavButton>
      ))}
    </nav>
  );
};
