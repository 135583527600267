import * as React from 'react';
import { Button } from '@goldbely/explore-component-library';
import { useDispatch } from 'react-redux';

import { open, switchMode } from 'src/explore/compositions/Auth/actions';

export const Identification: React.FC = () => {
  const dispatch = useDispatch();

  const handleLogin = () => {
    dispatch( switchMode( 'login' ));
    dispatch( open());
  };

  const handleRegister = () => {
    dispatch( switchMode( 'signup' ));
    dispatch( open());
  };

  return (
    <>
      <strong className="d-block mt-4 mb-6 fs-9 text-uppercase">Account</strong>

      <Button fullWidth type="button" onClick={handleLogin}>
        Log In
      </Button>

      <Button className="mt-2" fullWidth outline type="button" onClick={handleRegister}>
        Register
      </Button>
    </>
  );
};
