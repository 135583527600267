import * as React from 'react';
import cls from 'classnames';
import { INavItem } from 'src/explore/types/shoppe';

import styles from './index.module.sass';

interface Props {
  children?: React.ReactNode;
  className?: string;
  link: INavItem;
  onClick?: () => void;
}

export const NavLink: React.FC<Props> = ({
  children,
  className,
  link: { external, id, url, turbolinksDisabled },
  onClick,
}) => {
  if ( url ) {

    return (
      <a
        className={cls( styles.link, 'text-decoration-none', className )}
        id={id ? `fs__${id}` : ''}
        href={url}
        data-turbolinks={turbolinksDisabled && 'false'}
        onClick={onClick}
        target={ external ? '_blank' : undefined }
        rel={ external ? 'noopener noreferrer' : undefined }
      >
        {children}
      </a>
    );
  }

  return <span className={className}>{children}</span>;
};
