import * as React from 'react';
import cls from 'classnames';

import styles from './index.module.sass';

interface Props {
  includeDesktopStyle?: boolean;
  visible: boolean;
  onClick: () => void;
}

export const Overlay: React.FC<Props> = ({ includeDesktopStyle, visible, onClick }) => (
  <div
    className={cls( styles.overlay, 'position-absolute d-none', {
      'd-block': visible,
      'd-xl-none': includeDesktopStyle && visible,
    })}
    onClick={onClick}
  />
);
