import * as React from 'react';
import cls from 'classnames';

import styles from './index.module.sass';

interface Props {
  active: boolean;
  children: React.ReactNode;
  className?: string;
  includeDesktopStyle?: boolean;
  position: string;
}

export const Tab: React.FC<Props> = ({
  active,
  children,
  className,
  includeDesktopStyle,
  position,
}) => (
  <li
    className={cls( styles.tab, 'w-100', className, {
      [styles.active]: active,
      [styles.left]: !active && position === 'left',
      [styles.right]: !active && position === 'right',
      [`${styles.desktop} pb-xl-0`]: includeDesktopStyle,
    })}
  >
    {children}
  </li>
);
