/* eslint-disable no-use-before-define */
import * as React from 'react';
import { useSelector } from 'react-redux';

import accountImg from 'images/header/account@2x.jpg';
import helpImg from 'images/header/help@2x.jpg';

import { ntc } from 'src/explore/services/currency_helper';

import { INavItem } from 'src/explore/types/shoppe';
import { useRoutes } from '../useRoutes';

export function useNavigationMenus() {
  return {
    accountMenu: useAccountMenu(),
    helpMenu: useHelpMenu(),
    primaryMenu: usePrimaryMenu(),
    secondaryMobileMenu: useSecondaryMenuMobile(),
  };
}

function useAccountMenu() {
  const account = useSelector(( state: any ) => state.data.account );
  const routes = useRoutes();

  return React.useMemo(() => {
    const menu = {
      children: [
        {
          children: [
            { id: 100, title: 'My Orders', url: routes.account.orders() },
            { id: 101, title: 'My Info', url: routes.account.root() },
            { id: 102, title: 'My Favorites', url: routes.account.favorites() },
          ],
          id: 10,
          /* eslint-disable-next-line camelcase */
          title: `Hi ${account.data?.first_name || 'Food Explorer'}`,
        },
        {
          children: [
            { id: 110, title: 'My Journey', url: routes.account.journey() },
            { id: 112, title: 'Rewards', url: routes.rewards() },
            {
              id: 113,
              title: 'Sign Out',
              turbolinksDisabled: true,
              url: routes.auth.logOut(),
            },
          ],
          id: 11,
          title: '',
        },
      ],
      featuredImageUrl: accountImg,
      id: 1,
      title: 'Account',
    };

    /* eslint-disable-next-line camelcase */
    if ( account.data?.eligible_credits_balance_in_cents ) {
      menu.children[0].children.unshift({
        id: 103,
        title: `My Credit: ${ntc( account.data.eligible_credits_balance_in_cents, {
          verbose: true,
          precision: 0,
        })}`,
        // @ts-ignore why is this false?
        url: false,
      });
    }
    return menu;
  }, [ account, routes ]);
}

function useHelpMenu() {
  const routes = useRoutes();

  return React.useMemo(
    () => ({
      children: [
        {
          children: [
            {
              id: 200,
              title: 'About Us',
              url: routes.about(),
            },
            {
              id: 201,
              title: 'Blog',
              url: routes.blog.root(),
            },
            {
              id: 202,
              title: 'Press',
              url: routes.press(),
            },
            {
              id: 203,
              title: 'Jobs & Teams',
              url: routes.jobs(),
            },
          ],
          id: 20,
          title: 'About',
        },
        {
          children: [
            {
              id: 210,
              title: 'Check Order Status',
              url: routes.tracking(),
            },
            {
              id: 210,
              title: 'Check Gift Card Balance',
              url: routes.giftCardBalance(),
            },
            {
              external: true,
              id: 212,
              title: 'Customer Care',
              url: routes.help.root(),
            },
            {
              id: 213,
              title: 'Corporate Gifts',
              url: routes.corporate(),
            },
            {
              external: true,
              id: 214,
              title: 'Email us!',
              url: routes.help.newRequest(),
            },
          ],
          id: 21,
          title: 'Help',
        },
      ],
      featuredImageUrl: helpImg,
      id: 2,
      title: 'About & Help',
    }),
    [ routes ]
  );
}

function usePrimaryMenu() {
  // TODO: remove navItems, backwards compatibility
  // @ts-ignore missing window types
  const { navItems, navMenus = {}} = window;

  return ( navItems || navMenus.headerMain ) as INavItem | undefined;
}

function useSecondaryMenuMobile() {
  const routes = useRoutes();

  return React.useMemo(
    () => ({
      children: [
        {
          id: 204,
          title: 'E-Gift Cards',
          url: routes.giftCards.giftCard(),
        },
        {
          id: 213,
          title: 'Corporate Gifts',
          url: routes.corporate(),
        },
        {
          id: 210,
          title: 'Check Order Status',
          url: routes.tracking(),
        },
        {
          id: 211,
          title: 'Check Gift Card Balance',
          url: routes.giftCardBalance(),
        },
        {
          external: true,
          id: 212,
          title: 'Customer Care',
          url: routes.help.root(),
        },
        {
          id: 200,
          title: 'About Us',
          url: routes.about(),
        },
        {
          id: 202,
          title: 'Press',
          url: routes.press(),
        },
        {
          id: 203,
          title: 'Jobs & Teams',
          url: routes.jobs(),
        },
        {
          id: 201,
          title: 'Blog',
          url: routes.blog.root(),
        },
      ],
      id: 3,
      title: 'Secondary',
    }),
    [ routes ]
  );
}
