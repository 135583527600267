import React, { FC } from 'react';
import { Button } from '@goldbely/explore-component-library';

import { useRoutes } from 'src/explore/hooks/useRoutes';

const LogOut: FC = () => {
  const routes = useRoutes();

  return (
    <Button data-turbolinks="false" href={routes.auth.logOut()} outline type="button">
      Log Out
    </Button>
  );
};

export default LogOut;
