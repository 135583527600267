import * as React from 'react';
import { useDispatch } from 'react-redux';
import cls from 'classnames';

import { open, switchMode } from 'src/explore/compositions/Auth/actions';

import styles from './index.module.sass';

export const AuthButtons = ({
  className,
  buttonClassName,
}: {
  className?: string;
  buttonClassName?: string;
}) => {
  const dispatch = useDispatch();

  const handleSignup = () => {
    dispatch( switchMode( 'signup' ));
    dispatch( open());
  };

  const handleLogin = () => {
    dispatch( switchMode( 'login' ));
    dispatch( open());
  };

  return (
    <div className={className}>
      <button
        aria-label="Log In"
        className={cls(
          buttonClassName,
          styles.button,
          `d-inline p-0 border-0 bg-transparent font-weight-bold spec__log-in-trigger`
        )}
        type="button"
        onClick={handleLogin}
      >
        Log In
      </button>
      {' | '}
      <button
        aria-label="Sign Up"
        className={cls(
          buttonClassName,
          styles.button,
          `d-inline p-0 border-0 font-weight-bold bg-transparent`
        )}
        type="button"
        onClick={handleSignup}
      >
        Sign Up
      </button>
    </div>
  );
};
