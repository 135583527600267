import * as React from 'react';
import cls from 'classnames';

import animateHeight from 'src/animations/animateHeight';
import Breakpoints from 'src/explore/services/breakpoints';
import { useClickOutside } from 'src/explore/hooks/useClickOutside';
import { INavItem } from 'src/explore/types/shoppe';

import { NavButton } from 'src/explore/elements/NavButton';
import { NavLink } from 'src/explore/elements/NavLink';
import { Subgroup } from './elements/Subgroup';

import styles from './index.module.sass';

type IAnimateHeight = () => ( arg0: {
  open: boolean;
  minHeight: number;
  callback: () => void;
}) => void;

interface Props {
  id: number;
  includeDesktopStyle?: boolean;
  isOpen: boolean;
  linkClass?: string;
  links: INavItem[];
  menuRef?: any; // TODO: fix type
  title: string;
  onClickBack: () => void;
  onClickOutside: () => void;
  onClickSubgroup: () => void;
  onClickTitle: () => void;
  onHoverSubgroup?: ( subgroup: boolean ) => void;
}

export const Group: React.FC<Props> = ({
  id,
  includeDesktopStyle,
  isOpen,
  linkClass,
  links,
  menuRef,
  title,
  onClickBack,
  onClickOutside,
  onClickSubgroup,
  onClickTitle,
  onHoverSubgroup,
}) => {
  const [ pristine, setPristine ] = React.useState<boolean>( true );

  const [ openSubgroup, setOpenSubgroup ] = React.useState<number>( null );
  const [ scrollOffset, setScrollOffset ] = React.useState<number>( 0 );

  const buttonRef = React.useRef( null );
  const containerRef = React.useRef( null );
  const contentRef = React.useRef( null );

  const toggleAnimation = React.useCallback<IAnimateHeight>(
    () =>
      animateHeight({
        container: containerRef.current,
        content: contentRef.current,
        duration: 200,
      }),
    []
  );

  const isDesktop = Breakpoints.appliesTo( 'lg' ) && includeDesktopStyle;

  React.useEffect(() => {
    if ( !isDesktop ) {
      toggleAnimation()({ open: !isOpen, minHeight: 0, callback: () => setPristine( false ) });
    }
  }, [ isOpen ]);

  const handleClickBack = () => {
    setOpenSubgroup( null );
    onClickBack();
  };

  const handleClickLink = () => {
    if ( isDesktop ) {
      onClickOutside();
    }
  };

  const handleClickSubgroup = ( n: number ) => {
    if ( !isDesktop ) {
      if ( !openSubgroup && menuRef.current ) {
        setScrollOffset( menuRef.current.scrollTop );
      }

      if ( openSubgroup === n ) {
        setOpenSubgroup( null );
      } else {
        setOpenSubgroup( n );
      }
      onClickSubgroup();
    }
  };

  const handleMouseEnterSubgroup = ( n: number ) => {
    if ( isDesktop ) {
      setOpenSubgroup( n );
      onHoverSubgroup( true );
    }
  };

  const handleMouseLeaveSubgroup = () => {
    if ( isDesktop ) {
      setOpenSubgroup( null );
      onHoverSubgroup( false );
    }
  };

  useClickOutside( containerRef.current, ( event: React.MouseEvent ) => {
    if ( isDesktop && isOpen && !buttonRef.current?.contains( event.target )) {
      onClickOutside();
    }
  });

  return (
    <>
      <NavButton
        className={cls( styles.title, 'text-uppercase', linkClass, {
          [`${styles.desktop} d-xl-inline-block py-xl-4`]: includeDesktopStyle,
          [styles.open]: isOpen,
        })}
        id={`fs__${id}`}
        ref={buttonRef}
        onClick={onClickTitle}
      >
        {title}
      </NavButton>

      <div
        className={cls( styles.container, 'fs-4 pl-4 mr-n5', {
          [`${styles.desktop} px-xl-4 mr-xl-0`]: includeDesktopStyle,
          [styles.initial]: pristine,
          [styles.open]: isOpen,
        })}
        ref={containerRef}
      >
        <ul
          className={cls( 'pb-8 overflow-hidden', { 'pb-xl-0': includeDesktopStyle })}
          ref={contentRef}
        >
          {links.map(( link, i ) => (
            <li
              className={cls( styles.link, 'pt-3 mt-3', {
                'd-xl-inline-block pt-xl-0 mt-xl-0': includeDesktopStyle,
                [styles.muted]: openSubgroup !== null,
              })}
              key={link.title}
            >
              {!link.children && (
                <NavLink
                  className={cls( 'd-block w-100', {
                    'py-xl-4': includeDesktopStyle,
                  })}
                  link={link}
                  onClick={handleClickLink}
                >
                  {link.title}
                </NavLink>
              )}

              {link.children && (
                <Subgroup
                  includeDesktopStyle={includeDesktopStyle}
                  navItem={link}
                  open={openSubgroup === i}
                  scrollOffset={scrollOffset}
                  title={link.title}
                  onClickBack={handleClickBack}
                  onClickLink={handleClickLink}
                  onClickSubgroup={() => handleClickSubgroup( i )}
                  onMouseEnterSubgroup={() => handleMouseEnterSubgroup( i )}
                  onMouseLeaveSubgroup={handleMouseLeaveSubgroup}
                />
              )}
            </li>
          ))}
        </ul>
      </div>
    </>
  );
};
