import * as React from 'react';
import cls from 'classnames';

import styles from './index.module.sass';

interface Props {
  includeDesktopStyle?: boolean;
  visible: boolean;
}

export const Underlay: React.FC<Props> = ({ includeDesktopStyle, visible }) => (
  <div
    className={cls( styles.underlay, 'position-fixed', {
      [styles.open]: visible,
      [styles.desktop]: includeDesktopStyle,
    })}
  />
);
