import * as React from 'react';
import cls from 'classnames';

import styles from './index.module.sass';

interface Props {
  className?: string;
  children: React.ReactNode;
  id?: string;
  onClick?: ( event: React.MouseEvent ) => void;
}

export const NavButton = React.forwardRef<HTMLButtonElement, Props>(
  ({ className, children, id, onClick, ...attrs }, ref ) => (
    <button
      className={cls( styles.button, className )}
      id={id}
      ref={ref}
      type="button"
      onClick={onClick}
      {...attrs}
    >
      {children}
    </button>
  )
);
