import * as React from 'react';
import cls from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/pro-light-svg-icons';
import { Icon } from '@goldbely/explore-component-library';
import { useDispatch, useSelector } from 'react-redux';

import { AuthButtons } from 'src/explore/elements/AuthButtons';
import { CartButton } from 'src/shoppe/elements/CartButton';
import { ContainerXXL } from 'src/explore/elements/ContainerXXL';
import { GlobalSearch } from 'src/explore/elements/GlobalSearch';
import { NavButton } from 'src/explore/elements/NavButton';
import { NavLink } from 'src/explore/elements/NavLink';
import { NavMenu } from 'src/explore/elements/NavMenu';
import { NavToggle } from 'src/shoppe/elements/NavToggle';
import NumberShortener from 'src/explore/services/NumberShortener';
import { SkipToContent } from 'src/explore/elements/SkipToContent';
import { useNavigationMenus } from 'src/explore/hooks/useNavigationMenus';
import { useRoutes } from 'src/explore/hooks/useRoutes';
import BreakpointsService from 'src/explore/services/breakpoints';

import * as mobileMenuActionCreators from './actions';
import { SecondaryMenu } from './elements/SecondaryMenu';

import styles from './index.module.sass';

export const SiteHeader: React.FC = () => {
  const account = useSelector(( state: any ) => state.data.account );
  const showMobileNav = useSelector(( state: any ) => state.SiteHeader.open );

  const dispatch = useDispatch();

  const accountMenuButtonRef = React.useRef<HTMLButtonElement>( null );
  const containerRef = React.useRef<HTMLDivElement>( null );
  const secondaryMenuButtonRef = React.useRef<HTMLButtonElement>( null );
  const stickyRef = React.useRef<HTMLDivElement>( null );

  const routes = useRoutes();

  const [ expandSearch, setExpandSearch ] = React.useState( false );
  const [ priorScrollY, setPriorScrollY ] = React.useState( window.scrollY );
  const [ scrollDirection, setScrollDirection ] = React.useState( null );
  const [ slideOpen, setSlideOpen ] = React.useState<string>( '' );
  const [ stickyPosition, setStickyPosition ] = React.useState( 0 );

  const { accountMenu, helpMenu, primaryMenu, secondaryMobileMenu } = useNavigationMenus();

  const handleClickMenu = ( menu: string ) =>
    slideOpen === menu ? setSlideOpen( '' ) : setSlideOpen( menu );

  const handleNavigationClose = () => {
    dispatch<any>( mobileMenuActionCreators.toggle( false ));
  };

  const handleNavToggle = () => {
    dispatch<any>( mobileMenuActionCreators.toggle( !showMobileNav ));
  };

  React.useEffect(() => {
    document.body.classList.add( 'hide-side-nav-desktop' );
    return () => document.body.classList.remove( 'hide-side-nav-desktop' );
  }, []);

  React.useEffect(() => {
    if ( showMobileNav ) {
      document.body.classList.add( 'show-side-nav' );
    } else {
      document.body.classList.remove( 'show-side-nav' );
    }
  }, [ showMobileNav ]);

  React.useEffect(() => {
    const stickyScroll = () => {
      if ( containerRef.current && stickyRef.current ) {
        const stickyHeight = BreakpointsService.lessThan( 'lg' ) ? 72 : 162;
        const stickyTranslateY = stickyRef.current.style.transform.match(
          /translateY\s?\(\s?(-?\d+).*\)/
        )
          ? parseFloat( stickyRef.current.style.transform.match( /translateY\s?\(\s?(-?\d+).*\)/ )[1])
          : 0;

        // reset sticky position when scroll direction changes,
        // unless header is currently mid-translation around a position
        if ( window.scrollY < priorScrollY ) {
          if ( scrollDirection !== 'up' && stickyTranslateY < -stickyHeight ) {
            setStickyPosition( Math.max( priorScrollY - stickyHeight, 0 ));
          }
          setScrollDirection( 'up' );
        } else if ( window.scrollY > priorScrollY ) {
          if ( scrollDirection !== 'down' && stickyTranslateY === 0 ) {
            setStickyPosition( Math.max( priorScrollY, 0 ));
          }
          setScrollDirection( 'down' );
        }

        const verticalTranslation = () => {
          if ( window.scrollY <= stickyPosition ) {
            stickyRef.current.style.transform = 'translateY( 0 )';
          } else {
            stickyRef.current.style.transform = `translateY( ${
              stickyPosition - window.scrollY
            }px )`;
          }
        };
        // lock at top of page
        if ( window.scrollY <= 0 ) {
          containerRef.current.style.position = 'absolute';
          stickyRef.current.style.transform = 'translateY( 0 )';
          stickyRef.current.style.transition = 'none';
        }
        // translate around sticky position to mirror scrolling
        else if ( window.scrollY > stickyHeight && containerRef.current.style.position !== 'fixed' ) {
          containerRef.current.style.position = 'fixed';
          verticalTranslation();
        } else if ( containerRef.current.style.position === 'fixed' ) {
          stickyRef.current.style.transition = 'transform 80ms ease-out';
          verticalTranslation();
        }

        // close secondary menus on scroll
        setSlideOpen( '' );

        setPriorScrollY( window.scrollY );
      }
    };
    stickyScroll();

    window.addEventListener( 'scroll', stickyScroll );
    return () => window.removeEventListener( 'scroll', stickyScroll );
  }, [ stickyPosition, priorScrollY, scrollDirection ]);

  return (
    <div
      className={cls( styles.header, 'spec__site-header gtm__header', {
        [styles.showMobileNav]: showMobileNav,
      })}
      ref={containerRef}
      role="banner"
    >
      <div
        className={cls( styles.sticky, {
          [styles.showMobileNav]: showMobileNav,
        })}
        ref={stickyRef}
      >
        <SkipToContent offset="header--explore" />

        <SecondaryMenu
          className={styles.secondary}
          items={helpMenu}
          open={slideOpen !== ''}
          selected={slideOpen === helpMenu.title}
          onClose={() => setSlideOpen( '' )}
          onClickOutside={( event: React.MouseEvent<HTMLElement, MouseEvent> ) => {
            if (
              slideOpen === helpMenu.title &&
              !secondaryMenuButtonRef.current?.contains( event.target as Node )
            ) {
              setSlideOpen( '' );
            }
          }}
        />

        <SecondaryMenu
          className={`${styles.account} spec__account-menu`}
          items={accountMenu}
          open={slideOpen !== ''}
          selected={slideOpen === accountMenu.title}
          onClose={() => setSlideOpen( '' )}
          onClickOutside={( event: React.MouseEvent<HTMLElement, MouseEvent> ) => {
            if (
              slideOpen === accountMenu.title &&
              !accountMenuButtonRef.current?.contains( event.target as Node )
            ) {
              setSlideOpen( '' );
            }
          }}
        />

        <div
          className={cls(
            styles.container,
            'position-absolute py-5 py-md-4 py-xl-6 bg-white text-dark font-weight-bold fs-4 js__header',
            {
              [styles.slide]: slideOpen !== '',
            }
          )}
        >
          <ContainerXXL className="d-flex justify-content-between align-items-center pl-xl-5">
            <NavToggle
              className={{
                container: cls( styles.menu, 'd-xl-none mr-6 mr-md-8', {
                  [styles.hide]: expandSearch,
                }),
              }}
              isActive={showMobileNav}
              onClick={handleNavToggle}
            />

            <div className="flex-md-grow-1 flex-xl-grow-0 flex-shrink-0 mr-6 mr-md-7 mx-xl-0">
              <a className={`${styles.logo} d-block mx-0`} href="/">
                <Icon
                  ariaLabel="Goldbelly"
                  className={cls( styles.goldbelly, 'd-block w-100', {
                    [styles.hide]: expandSearch,
                  })}
                  name="goldbelly"
                  width={128}
                />
              </a>
            </div>

            <div className="d-none d-xl-flex justify-content-between pr-xl-10 pl-xl-7">
              <NavButton
                className={cls( styles.button, 'mx-xl-2 text-uppercase' )}
                id="fs__2"
                onClick={() => handleClickMenu( helpMenu.title )}
                ref={secondaryMenuButtonRef}
              >
                {helpMenu.title}
              </NavButton>
            </div>

            <div className="position-relative flex-grow-1 align-self-start align-self-md-auto">
              <GlobalSearch
                classNames={{
                  form: cls( styles.search, {
                    [styles.expand]: expandSearch,
                  }),
                }}
                clearOnBlur
                onBlur={() => setExpandSearch( false )}
                onFocus={() => setExpandSearch( true )}
              />
            </div>

            <div className="position-relative pl-2 pl-md-1 pl-xl-0 ml-md-3 ml-xl-7">
              <div
                className={cls(
                  styles.customer,
                  'd-flex justify-content-end justify-content-xl-between spec__login-container',
                  {
                    [styles.hide]: expandSearch,
                  }
                )}
              >
                {account.data && (
                  <>
                    <NavButton
                      className={cls(
                        styles.button,
                        'd-none d-xl-block mt-1 mr-6 mr-xl-2 ml-2 text-uppercase spec__account-menu-trigger'
                      )}
                      id="fs__1"
                      onClick={() => handleClickMenu( accountMenu.title )}
                      ref={accountMenuButtonRef}
                    >
                      <div
                        className={`${styles.name} d-inline-block text-truncate spec__logged-in-as`}
                      >
                        {`Hi ${account.data.first_name || 'Food Explorer'}`}
                      </div>
                      {account.data.reward_points > 0 && (
                        <div className={`${styles.points} fs-3 text-truncate`}>
                          {`(${NumberShortener.shorten( account.data.reward_points, {
                            min: 10000,
                          })} Reward Points)`}
                        </div>
                      )}
                    </NavButton>
                    <div className="d-flex pl-2 px-md-1 px-xl-5 mt-1">
                      <NavLink
                        className={`${styles.link} d-none d-xl-inline text-uppercase`}
                        link={{ id: 100, title: 'My Orders', url: routes.account.orders() }}
                      >
                        My Orders
                      </NavLink>
                      <CartButton className="ml-4 ml-xl-12" />
                    </div>
                  </>
                )}

                {!account.pristine && !account.data && (
                  <>
                    <AuthButtons
                      className="d-none d-xl-block px-2"
                      buttonClassName="text-uppercase"
                    />
                    <CartButton className="ml-5 ml-xl-9" />
                  </>
                )}
              </div>

              <button
                className={cls(
                  styles.close,
                  'position-absolute p-0 border-0 my-auto bg-transparent fs-5',
                  {
                    [styles.show]: expandSearch,
                  }
                )}
                type="button"
                onClick={() => setExpandSearch( false )}
              >
                <FontAwesomeIcon icon={faXmark} />
              </button>
            </div>
          </ContainerXXL>
        </div>

        <NavMenu
          className={cls({
            [styles.slide]: slideOpen !== '',
          })}
          isOpen={!!showMobileNav}
          primary={primaryMenu}
          secondary={secondaryMobileMenu}
          onNavigation={handleNavigationClose}
          onClose={handleNavigationClose}
        />
      </div>
    </div>
  );
};
