import * as React from 'react';
import cls from 'classnames';
import Imgix from 'react-imgix';

import { useClickOutside } from 'src/explore/hooks/useClickOutside';
import { NavLink } from 'src/explore/elements/NavLink';
import { INavItem } from 'src/explore/types/shoppe';

import { Close } from './elements/Close';

import styles from './index.module.sass';

interface Props {
  className?: string;
  items: INavItem;
  open: boolean;
  selected: boolean;
  onClose: () => void;
  onClickOutside: ( event: React.MouseEvent<HTMLElement, MouseEvent> ) => void;
}

export const SecondaryMenu: React.FC<Props> = ({
  className,
  items,
  open,
  selected,
  onClose,
  onClickOutside,
}) => {
  const containerRef = React.useRef( null );

  useClickOutside( containerRef.current, onClickOutside );

  return (
    <div
      className={cls( styles.container, 'position-absolute w-100 overflow-hidden', className, {
        [styles.open]: open,
        [styles.selected]: selected,
      })}
      ref={containerRef}
    >
      <div
        className={cls( styles.content, 'd-flex align-items-end w-100', {
          [styles.selected]: selected,
        })}
      >
        <div className="container-xl position-relative spec__header">
          <div className="row align-items-end">
            <div className="col-6">
              <ul className="row">
                {items.children.map(( item ) => (
                  <li className="col-6 pb-9" key={item.title}>
                    {item.title && (
                      <div className="font-weight-bold fs-8 text-uppercase text-truncate">
                        {item.title}
                      </div>
                    )}
                    <ul className={cls({ [styles.noTitle]: !item.title })}>
                      {item.children.map(( child ) => (
                        <li className="mt-5" key={child.title}>
                          <NavLink
                            className={cls( styles.link, 'd-inline-block', {
                              [`${styles.outline} btn btn-outline-dark fs-4 spec__sign-out-link`]:
                                child.url === '/sign-out',
                            })}
                            link={child}
                            onClick={onClose}
                          >
                            {child.title}
                          </NavLink>
                        </li>
                      ))}
                    </ul>
                  </li>
                ))}
              </ul>
            </div>
            {items.featuredImageUrl && (
              <div className="col-3 d-flex align-items-end">
                <Imgix
                  htmlAttributes={{ alt: items.title }}
                  className="w-100 mx-auto"
                  imgixParams={{ ar: '1:1' }}
                  sizes="20vw"
                  src={items.featuredImageUrl.toString()}
                />
              </div>
            )}
          </div>
          <Close onClick={onClose} />
        </div>
      </div>
    </div>
  );
};
