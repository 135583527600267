import * as React from 'react';

interface Props {
  title: string;
  value: string | number;
}

export const Balance: React.FC<Props> = ({ title, value }) => (
  <>
    <span className="fs-3">{title}</span>
    <strong className="d-block mt-1 fs-8">{value}</strong>
  </>
);
