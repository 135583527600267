import * as React from 'react';
import cls from 'classnames';

import styles from './index.module.sass';

interface Props {
  children: React.ReactNode;
  className?: string;
  containerElement?: React.ElementType;
}

export const ContainerXXL: React.FC<Props> = ({
  children,
  className,
  containerElement = 'div',
}) => {
  const Container = containerElement;

  return (
    <Container className={cls( styles.container, 'w-100 mx-auto', className )}>{children}</Container>
  );
};
