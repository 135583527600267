import * as React from 'react';
import cls from 'classnames';

import { INavItem } from 'src/explore/types/shoppe';
import { Account } from './elements/Account';
import { Links } from './elements/Links';
import { Tab } from './elements/Tab';

import styles from './index.module.sass';

interface Props {
  includeDesktopStyle?: boolean;
  menuRef?: any; // TODO: fix type
  primaryLinks?: INavItem[];
  secondaryLinks: INavItem[];
  showSubgroup: boolean;
  tab: number;
  onClickBack: () => void;
  onClickSubgroup: () => void;
  onHoverSubgroup: ( subgroup: boolean ) => void;
  onNavigation: () => void;
}

export const Tabs: React.FC<Props> = ({
  includeDesktopStyle,
  menuRef,
  primaryLinks,
  secondaryLinks,
  showSubgroup,
  tab,
  onClickBack,
  onClickSubgroup,
  onHoverSubgroup,
  onNavigation,
}) => (
  <ul className={cls( styles.container, 'w-100', { [styles.desktop]: includeDesktopStyle })}>
    <Tab
      active={tab === 0}
      className="js__header-navigation"
      includeDesktopStyle={includeDesktopStyle}
      position={tab > 0 ? 'left' : 'right'}
    >
      <div className={cls( styles.panel, 'px-5', { [styles.subgroup]: showSubgroup })}>
        <Links
          containerClass={cls( 'fs-8', {
            'd-xl-flex justify-content-xl-center fs-xl-4 text-xl-center': includeDesktopStyle,
          })}
          includeDesktopStyle={includeDesktopStyle}
          itemClass={cls( styles.primaryItem, 'mt-4', {
            'mt-xl-0': includeDesktopStyle,
          })}
          linkActiveClass={styles.primaryLinkActive}
          linkClass={cls( styles.primaryLink, 'position-relative text-uppercase', {
            'd-xl-inline-block py-xl-5': includeDesktopStyle,
          })}
          links={primaryLinks}
          menuRef={menuRef}
          onClickBack={onClickBack}
          onClickSubgroup={onClickSubgroup}
          onHoverSubgroup={onHoverSubgroup}
          onNavigation={onNavigation}
        />
        <Links
          containerClass={cls( styles.secondary, 'pt-4 pl-4 mt-8 mr-n5 fs-5', {
            'd-xl-none': includeDesktopStyle,
          })}
          includeDesktopStyle={includeDesktopStyle}
          itemClass={`${styles.secondaryItem} pt-3 mt-3`}
          linkClass={styles.secondaryLink}
          links={secondaryLinks}
          onNavigation={onNavigation}
        />
      </div>
    </Tab>

    <Tab
      active={tab === 1}
      className={`${styles.account} px-5`}
      includeDesktopStyle={includeDesktopStyle}
      position={tab > 1 ? 'left' : 'right'}
    >
      <Account onNavigation={onNavigation} />
    </Tab>
  </ul>
);
