import * as React from 'react';
import cls from 'classnames';
import { faPlayCircle } from '@fortawesome/pro-duotone-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { INavItem } from 'src/explore/types/shoppe';
import { NavLink } from 'src/explore/elements/NavLink';

import { Group } from './elements/Group';

import styles from './index.module.sass';

const ICONS: { [key: string]: any } = {
  'Shop By Video': <FontAwesomeIcon className={`${styles.play} mr-1`} icon={faPlayCircle} />,
};

interface Props {
  containerClass?: string;
  includeDesktopStyle?: boolean;
  itemClass?: string;
  linkActiveClass?: string;
  linkMutedClass?: string;
  linkClass?: string;
  links?: INavItem[];
  menuRef?: any; // TODO: fix type
  onClickBack?: () => void;
  onClickSubgroup?: () => void;
  onHoverSubgroup?: ( subgroup: boolean ) => void;
  onNavigation: () => void;
}

export const Links: React.FC<Props> = ({
  containerClass,
  includeDesktopStyle,
  itemClass = 'mt-4',
  linkActiveClass,
  linkMutedClass,
  linkClass,
  links,
  menuRef,
  onClickBack,
  onClickSubgroup,
  onHoverSubgroup,
  onNavigation,
}) => {
  const [ open, setOpen ] = React.useState<number>( null );

  if ( !links ) {
    return null;
  }

  return (
    <ul
      className={cls( containerClass, {
        [styles.container]: includeDesktopStyle,
        [styles.open]: open !== null,
      })}
    >
      {links.map(( link, i ) => (
        <li className={cls( itemClass, { 'd-xl-inline': includeDesktopStyle })} key={link.title}>
          {!link.children && (
            <NavLink
              className={cls( linkClass, {
                [linkMutedClass || '']: open !== null,
                [linkActiveClass]:
                  ( window as any ).location.pathname ===
                  link.url.match( /((https?:\/\/)?(www.)?goldbelly.com)?(.+$)/ )[4],
              })}
              link={link}
              onClick={onNavigation}
            >
              {ICONS[link.title]}
              {link.title}
            </NavLink>
          )}

          {link.children && (
            <Group
              id={link.id}
              includeDesktopStyle={includeDesktopStyle}
              isOpen={i === open}
              linkClass={cls( linkClass )}
              links={link.children}
              menuRef={menuRef}
              title={link.title}
              onClickBack={onClickBack}
              onClickOutside={() => setOpen( null )}
              onClickSubgroup={onClickSubgroup}
              onClickTitle={() => ( i === open ? setOpen( null ) : setOpen( i ))}
              onHoverSubgroup={onHoverSubgroup}
            />
          )}
        </li>
      ))}
    </ul>
  );
};
