import * as React from 'react';
import cls from 'classnames';

import { useRoutes } from 'src/explore/hooks/useRoutes';

import { Links } from '../../../Links';

import { Balance } from './elements/Balance';
import Help from './elements/Help';
import LogOut from './elements/LogOut';

import styles from './index.module.sass';

interface Props {
  credit: string;
  name: string;
  points: string;
  onNavigation: () => void;
}

export const Details: React.FC<Props> = ({ credit, name, points, onNavigation }) => {
  const routes = useRoutes();

  return (
    <>
      <strong className="d-block mt-3 fs-8 text-uppercase text-truncate">{`Hi ${name}`}</strong>

      {(( credit !== '' && credit !== '$0' ) || points !== '0' ) && (
        <div className="pl-5">
          <ul className={`${styles.balance} row position-relative pt-3 mt-3 mx-n5 mb-n3`}>
            {credit !== '' && credit !== '$0' && (
              <li className="col pr-5 pl-0">
                <Balance title="Credit" value={credit} />
              </li>
            )}
            {points !== '0' && (
              <li
                className={cls( 'col', {
                  'px-5': credit !== '' && credit !== '$0',
                  'pr-5 pl-0': credit === '',
                })}
              >
                <Balance title="Reward Points" value={points || 0} />
              </li>
            )}
          </ul>
        </div>
      )}

      <Links
        containerClass={`${styles.links} pt-4 pr-5 mt-7 mr-n5`}
        itemClass="mt-4 fs-8 text-uppercase"
        links={[
          { id: 100, title: 'Orders', url: routes.account.orders() },
          { id: 102, title: 'Personal Info', url: routes.account.root() },
          { id: 103, title: 'My Journey', url: routes.account.journey() },
          { id: 101, title: 'My Favorites', url: routes.account.favorites() },
        ]}
        onNavigation={onNavigation}
      />

      <Help onClick={onNavigation} />

      <div className={`${styles.logout} py-8 pr-5 mt-8 mr-n5`}>
        <LogOut />
      </div>
    </>
  );
};
