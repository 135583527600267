import * as React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/pro-regular-svg-icons';

import styles from './index.module.sass';

interface Props {
  onClick: () => void;
}

export const Close: React.FC<Props> = ({ onClick }) => (
  <button
    aria-label="close"
    className={`${styles.button} position-absolute border-0 bg-transparent`}
    type="button"
    onClick={onClick}
  >
    <FontAwesomeIcon icon={faTimes} size="3x" />
  </button>
);
