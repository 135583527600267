import * as React from 'react';
import { MenuItem } from 'react-bootstrap-typeahead';

import styles from './index.module.sass';

interface Props {
  option: {
    concept: string;
    displayName: string;
  };
  position: number;
}

export const OptionFactory: React.FC<Props> = ({ option, position }) => {
  const Item = React.useCallback(
    ({ children }: { children: React.ReactNode }) => (
      <MenuItem
        className={`${styles.item} d-block px-4 py-2 text-dark`}
        option={option}
        position={position}
      >
        {children}
      </MenuItem>
    ),
    [ option, position ]
  );

  switch ( option.concept ) {
    case 'foods':
      return (
        <Item>
          {`${option.displayName} in `}
          <span className="text-secondary">Foods</span>
        </Item>
      );
    case 'collections':
      return (
        <Item>
          {`${option.displayName} in `}
          <span className="text-secondary">Our Picks</span>
        </Item>
      );
    case 'holidays':
      return (
        <Item>
          {`${option.displayName} in `}
          <span className="text-secondary">Holiday Collection</span>
        </Item>
      );
    case 'shops':
      return (
        <Item>
          {`${option.displayName} `}
          <span className="text-secondary">Shop Page</span>
        </Item>
      );
    case 'gbtv':
      return (
        <Item>
          {`${option.displayName} `}
          <span className="text-secondary">Goldbelly TV</span>
        </Item>
      );
    default:
      return <Item>{option.displayName}</Item>;
  }
};
