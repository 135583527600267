import 'src/shared/polyfills';

import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import camelCaseKeys from 'camelcase-keys';
import { Provider } from 'react-redux';

import { EppoRandomizationProvider } from 'src/explore/providers/EppoRandomizationProvider';
import { init as initStore, store as initializedStore } from 'src/explore/store';
import { RoutesProvider } from 'src/explore/hooks/useRoutes';

import App from 'src/explore/scenes/App';
import Backdrop from 'src/explore/scenes/Backdrop';
import Cart from 'src/explore/scenes/Cart';
import SiteHeader from 'src/explore/scenes/SiteHeader';

// CSS
import 'src/css/explore/index.sass';
import ThemeSelector from 'src/explore/themes';
import { SSOButtons } from 'src/explore/compositions/SSOButtons';

// Lazy load this guy, it's huge
const AccountStats = React.lazy(() => import( 'src/explore/scenes/AccountStats' ));

// Also these guys are just one offs
const AccountFavorites = React.lazy(() => import( 'src/explore/scenes/AccountFavorites' ));
const AhoyVisitRevalidation = React.lazy(() => import( 'src/explore/scenes/AhoyVisitRevalidation' ));
const AnalyticsPixel = React.lazy(() => import( 'src/explore/scenes/AnalyticsPixel' ));
const Auth = React.lazy(() => import( 'src/explore/scenes/Auth' ));
const AppSignInSuccess = React.lazy(() => import( 'src/explore/scenes/AppSignInSuccess' ));
const BellyRewards = React.lazy(() => import( 'src/explore/scenes/BellyRewards' ));
const DeliveryDateChange = React.lazy(() => import( 'src/explore/scenes/DeliveryDateChange' ));
const Footer = React.lazy(() => import( 'src/explore/scenes/Footer' ));
const ForgotPassword = React.lazy(() => import( 'src/explore/scenes/ForgotPassword' ));
const Instructions = React.lazy(() => import( 'src/explore/scenes/Instructions' ));
const LiveClasses = React.lazy(() => import( 'src/explore/scenes/LiveClasses' ));
const Megaphone = React.lazy(() => import( 'src/shoppe/scenes/Megaphone' ));
const Newsletter = React.lazy(() => import( 'src/explore/scenes/Newsletter' ));
const Order = React.lazy(() => import( 'src/explore/scenes/Order' ));
const OrderAgainButton = React.lazy(() => import( 'src/explore/scenes/OrderAgainButton' ));
const OrderStatus = React.lazy(() => import( 'src/explore/scenes/OrderStatus' ));
const PrintEGiftCard = React.lazy(() => import( 'src/explore/scenes/PrintEGiftCard' ));
const ProductInstructions = React.lazy(() => import( 'src/explore/scenes/ProductInstructions' ));
const SaveRewards = React.lazy(() => import( 'src/explore/scenes/SaveRewards' ));
const SuborderOptions = React.lazy(() => import( 'src/explore/scenes/SuborderOptions' ));
const RecommendedProducts = React.lazy(() => import( 'src/explore/scenes/RecommendedProducts' ));
const ResendRecipientEmail = React.lazy(() => import( 'src/explore/scenes/ResendRecipientEmail' ));
const Rewards = React.lazy(() => import( 'src/explore/scenes/Rewards' ));
const ShopByRegion = React.lazy(() => import( 'src/explore/scenes/ShopByRegion' ));
const ShopByRestaurant = React.lazy(() => import( 'src/explore/scenes/ShopByRestaurant' ));
const State = React.lazy(() => import( 'src/explore/scenes/State' ));
const Success = React.lazy(() => import( 'src/explore/scenes/Success' ));
const TV = React.lazy(() => import( 'src/explore/scenes/TV' ));
const VideoModal = React.lazy(() => import( 'src/explore/scenes/VideoModal' ));
const Videos = React.lazy(() => import( 'src/explore/scenes/Videos' ));

const getStore = () => ( window.isMobileApp ? initStore() : initializedStore );

// Custom tags
class PlusCustomElement extends HTMLElement {}
window.customElements.define( 'plus-sign', PlusCustomElement );

// Temporary elements
document.addEventListener( 'turbolinks:load', () => {
  const accountStatsElem = document.getElementById( 'account-stats-root' );
  const analyticsPixelElem = document.getElementById( 'analytics-pixel-root' );
  const appElem = document.getElementById( 'app-root' );
  const appSignInSuccessElem = document.getElementById( 'app-sign-in-success-root' );
  const authElem = document.getElementById( 'auth-root' );
  const bellyRewardsElem = document.getElementById( 'belly-rewards-root' );
  const deliveryDateChangeElem = document.getElementById( 'delivery-date-change-root' );
  const favoriteCollectionElem = document.getElementById( 'favorite-collection-root' );
  const footerElem = document.getElementById( 'footer-root' );
  const forgotPasswordElem = document.getElementById( 'forgot-password-root' );
  const instructionsElem = document.getElementById( 'instructions-root' );
  const LiveClassesElem = document.getElementById( 'live-classes-root' );
  const megaphoneElem = document.getElementById( 'megaphone-root' );
  const newsletterElem = document.getElementById( 'newsletter-root' );
  const orderElems = document.getElementsByClassName( 'order-root' );
  const orderAgainButtonElems = document.getElementsByClassName( 'order-again-button-root' );
  const orderStatusElem = document.getElementById( 'order-status-root' );
  const preCartElem = document.getElementById( 'pre-cart-root' );
  const printEGiftCardElem = document.getElementById( 'print-e-gift-card-root' );
  const productInstructionsElem = document.getElementById( 'product-instructions-root' );
  const recommendedProductsElem = document.getElementById( 'recommended-products-root' );
  const resendRecipientEmailElems = document.getElementsByClassName( 'resend-recipient-email-root' );
  const rewardsElem = document.getElementById( 'rewards-root' );
  const saveRewardsElem = document.getElementById( 'save-rewards-root' );
  const sbrElem = document.getElementById( 'sbr-root' );
  const shopByRestaurantElem = document.getElementById( 'shop-by-restaurant-root' );
  const siteHeaderElem = document.getElementById( 'site-header-root' );
  const ssoButtonsElem = document.getElementById( 'sso-buttons-root' );
  const stateElem = document.getElementById( 'state-root' );
  const suborderOptionsElems = document.getElementsByClassName( 'suborder-options-root' );
  const successElem = document.getElementById( 'success-root' );
  const tvElem = document.getElementById( 'tv-root' );
  const videoModalElem = document.getElementById( 'video-modal-root' );
  const videosElem = document.getElementById( 'videos-root' );

  const store = getStore();

  // Only render if root element is present

  if ( accountStatsElem ) {
    ReactDOM.render(
      <Provider store={store}>
        <Suspense fallback={null}>
          <EppoRandomizationProvider>
            <AccountStats merchants={camelCaseKeys( window._top_merchants, { deep: true })} />
          </EppoRandomizationProvider>
        </Suspense>
      </Provider>,
      accountStatsElem
    );
  }

  if ( analyticsPixelElem ) {
    ReactDOM.render(
      <Provider store={store}>
        <Suspense fallback={null}>
          <AnalyticsPixel />
        </Suspense>
      </Provider>,
      analyticsPixelElem
    );
  }

  if ( appElem ) {
    ReactDOM.render(
      <Provider store={store}>
        <EppoRandomizationProvider>
          <App />
        </EppoRandomizationProvider>
        <Suspense fallback={null}>
          <AhoyVisitRevalidation />
        </Suspense>
      </Provider>,
      appElem
    );
  }

  if ( appSignInSuccessElem ) {
    ReactDOM.render(
      <Suspense fallback={null}>
        <ThemeSelector />
        <AppSignInSuccess />
      </Suspense>,
      appSignInSuccessElem
    );
  }

  if ( authElem ) {
    ReactDOM.render(
      <Provider store={store}>
        <Suspense fallback={null}>
          <RoutesProvider>
            <Auth />
          </RoutesProvider>
        </Suspense>
      </Provider>,
      authElem
    );
  }

  if ( bellyRewardsElem ) {
    ReactDOM.render(
      <Provider store={store}>
        <Suspense fallback={null}>
          <BellyRewards />
        </Suspense>
      </Provider>,
      bellyRewardsElem
    );
  }

  if ( LiveClassesElem ) {
    ReactDOM.render(
      <Provider store={store}>
        <Suspense fallback={null}>
          <LiveClasses />
        </Suspense>
      </Provider>,
      LiveClassesElem
    );
  }

  if ( deliveryDateChangeElem ) {
    ReactDOM.render(
      <Suspense fallback={null}>
        <RoutesProvider>
          <DeliveryDateChange adjustment={window._adjustment_suborder} />
        </RoutesProvider>
      </Suspense>,
      deliveryDateChangeElem
    );
  }

  if ( favoriteCollectionElem ) {
    ReactDOM.render(
      <Provider store={store}>
        <Suspense fallback={null}>
          <AccountFavorites />
        </Suspense>
      </Provider>,
      favoriteCollectionElem
    );
  }

  if ( forgotPasswordElem ) {
    ReactDOM.render(
      <Provider store={store}>
        <Suspense fallback={null}>
          <ForgotPassword />
        </Suspense>
      </Provider>,
      forgotPasswordElem
    );
  }

  if ( footerElem ) {
    ReactDOM.render(
      <Provider store={store}>
        <Suspense fallback={null}>
          <Footer />
        </Suspense>
      </Provider>,
      footerElem
    );
  }

  if ( instructionsElem ) {
    ReactDOM.render(
      <Suspense fallback={null}>
        <RoutesProvider>
          <Instructions instructions={window._instructions} />
        </RoutesProvider>
      </Suspense>,
      instructionsElem
    );
  }

  if ( megaphoneElem ) {
    ReactDOM.render(
      <Provider store={store}>
        <Suspense fallback={null}>
          <Megaphone />
        </Suspense>
      </Provider>,
      megaphoneElem
    );
  }

  if ( newsletterElem ) {
    ReactDOM.render(
      <Suspense fallback={null}>
        <Newsletter />
      </Suspense>,
      newsletterElem
    );
  }

  if ( orderElems ) {
    Array.from( orderElems ).forEach(( elem ) => {
      const hasCustomerInstructions = elem.hasAttribute( 'data-customer-instructions' );
      const instructionsPath = elem.getAttribute( 'data-instructions-path' );
      const liveClassID = elem.getAttribute( 'data-live-id' );
      const feedbackPath = elem.getAttribute( 'data-feedback-path' );
      const isReviewed = elem.hasAttribute( 'data-is-reviewed' );

      ReactDOM.render(
        <Suspense fallback={null}>
          <Order
            hasCustomerInstructions={hasCustomerInstructions}
            instructionsPath={instructionsPath}
            liveClassID={liveClassID}
            feedbackPath={feedbackPath}
            isReviewed={isReviewed}
          />
        </Suspense>,
        elem
      );
    });
  }

  if ( orderAgainButtonElems ) {
    Array.from( orderAgainButtonElems ).forEach(( elem ) => {
      const suborderId = parseInt( elem.getAttribute( 'data-suborder-id' ), 10 );
      const canAddToCart = elem.hasAttribute( 'data-can-add-to-cart' );

      ReactDOM.render(
        <Provider store={store}>
          <Suspense fallback={null}>
            {Number.isNaN( suborderId ) ? (
              <></>
            ) : (
              <OrderAgainButton suborderId={suborderId} canAddToCart={canAddToCart} />
            )}
          </Suspense>
        </Provider>,
        elem
      );
    });
  }

  if ( orderStatusElem ) {
    ReactDOM.render(
      <Provider store={store}>
        <Suspense fallback={null}>
          <RoutesProvider>
            <OrderStatus />
          </RoutesProvider>
        </Suspense>
      </Provider>,
      orderStatusElem
    );
  }

  if ( preCartElem ) {
    ReactDOM.render(
      <Provider store={store}>
        <Backdrop />

        <EppoRandomizationProvider>
          <Cart mode="precart" editable />
        </EppoRandomizationProvider>
      </Provider>,
      preCartElem
    );
  }

  if ( productInstructionsElem ) {
    ReactDOM.render(
      <Provider store={store}>
        <Suspense fallback={null}>
          <RoutesProvider>
            <ProductInstructions instructions={window._instructions} />
          </RoutesProvider>
        </Suspense>
      </Provider>,
      productInstructionsElem
    );
  }

  if ( printEGiftCardElem ) {
    const eGiftCardCode = printEGiftCardElem.getAttribute( 'data-code' );
    const eGiftCardFrom = printEGiftCardElem.getAttribute( 'data-from' );
    const eGiftCardMessage = printEGiftCardElem.getAttribute( 'data-message-to-recipient' );
    const eGiftCardTo = printEGiftCardElem.getAttribute( 'data-to' );
    const eGiftCardValue = printEGiftCardElem.getAttribute( 'data-dollar-value' );

    ReactDOM.render(
      <Suspense fallback={null}>
        <PrintEGiftCard
          code={eGiftCardCode}
          from={eGiftCardFrom}
          message={eGiftCardMessage}
          to={eGiftCardTo}
          value={eGiftCardValue}
        />
      </Suspense>,
      printEGiftCardElem
    );
  }

  if ( recommendedProductsElem ) {
    const clickRef = recommendedProductsElem.getAttribute( 'data-ref' );
    const type = recommendedProductsElem.getAttribute( 'data-type' );

    ReactDOM.render(
      <Provider store={store}>
        <Suspense fallback={null}>
          <EppoRandomizationProvider>
            <RecommendedProducts clickRef={clickRef} pageType={type} />
          </EppoRandomizationProvider>
        </Suspense>
      </Provider>,
      recommendedProductsElem
    );
  }

  if ( resendRecipientEmailElems ) {
    let id;

    Array.from( resendRecipientEmailElems ).forEach(( elem ) => {
      id = elem.getAttribute( 'data-id' );
      ReactDOM.render(
        <Suspense fallback={null}>
          <ResendRecipientEmail id={id} />
        </Suspense>,
        elem
      );
    });
  }

  if ( rewardsElem ) {
    ReactDOM.render(
      <Provider store={store}>
        <Suspense fallback={null}>
          <Rewards />
        </Suspense>
      </Provider>,
      rewardsElem
    );
  }

  if ( saveRewardsElem ) {
    ReactDOM.render(
      <Provider store={store}>
        <Suspense fallback={null}>
          <SaveRewards />
        </Suspense>
      </Provider>,
      saveRewardsElem
    );
  }

  if ( sbrElem ) {
    ReactDOM.render(
      <Provider store={store}>
        <Suspense fallback={null}>
          <EppoRandomizationProvider>
            <ShopByRegion />
          </EppoRandomizationProvider>
        </Suspense>
      </Provider>,
      sbrElem
    );
  }

  if ( ssoButtonsElem ) {
    ReactDOM.render(
      <Provider store={store}>
        <Suspense fallback={null}>
          <SSOButtons ssoOrigin="/" />
        </Suspense>
      </Provider>,
      ssoButtonsElem
    );
  }

  if ( shopByRestaurantElem ) {
    ReactDOM.render(
      <Provider store={store}>
        <RoutesProvider>
          <Suspense fallback={null}>
            <ShopByRestaurant categories={window._categories} />
          </Suspense>
        </RoutesProvider>
      </Provider>,
      shopByRestaurantElem
    );
  }

  if ( siteHeaderElem ) {
    ReactDOM.render(
      <Provider store={store}>
        <RoutesProvider>
          <SiteHeader />
        </RoutesProvider>
      </Provider>,
      siteHeaderElem
    );
  }

  if ( stateElem ) {
    const state = stateElem.getAttribute( 'data-state' );

    ReactDOM.render(
      <Provider store={store}>
        <Suspense fallback={null}>
          <State merchants={camelCaseKeys( window._merchants, { deep: true })} state={state} />
        </Suspense>
      </Provider>,
      stateElem
    );
  }

  if ( suborderOptionsElems ) {
    Array.from( suborderOptionsElems ).forEach(( elem ) => {
      const id = elem.getAttribute( 'data-id' );
      const orderType = elem.getAttribute( 'data-order-type' );
      const merchantNames = elem.getAttribute( 'data-merchant-names' );
      const placedByGuest = elem.hasAttribute( 'data-by-guest' );
      const giftMessageBody = elem.getAttribute( 'data-gift-message' );
      const giftMessageFrom = elem.getAttribute( 'data-gift-message-from' );
      const requestedDeliveryOn = elem.getAttribute( 'data-requested-delivery-on' );
      const shippingSubtotal = elem.getAttribute( 'data-shipping-subtotal' );
      const disableOptions = elem.hasAttribute( 'data-disable-options' );

      ReactDOM.render(
        <Provider store={store}>
          <Suspense fallback={null}>
            <SuborderOptions
              id={id}
              orderType={orderType}
              merchantNames={merchantNames}
              placedByGuest={placedByGuest}
              giftMessageBody={giftMessageBody}
              giftMessageFrom={giftMessageFrom}
              requestedDeliveryOn={requestedDeliveryOn}
              shippingSubtotal={shippingSubtotal}
              disabled={disableOptions}
            />
          </Suspense>
        </Provider>,
        elem
      );
    });
  }

  if ( successElem ) {
    const authMode = successElem.getAttribute( 'data-auth-mode' );
    const email = successElem.getAttribute( 'data-email' );
    const displayHowDidYouHearAboutUs = successElem.hasAttribute( 'data-display-heard-about-us' );
    const heardAboutUsOptions = JSON.parse( successElem.getAttribute( 'data-heard-about-us-options' ));

    ReactDOM.render(
      <Provider store={store}>
        <Suspense fallback={null}>
          <RoutesProvider>
            <Success
              authMode={authMode}
              confirmedOrder={camelCaseKeys( window.confirmedOrder )}
              email={email}
              heardAboutUsOptions={heardAboutUsOptions}
              displayHowDidYouHearAboutUs={displayHowDidYouHearAboutUs}
            />
          </RoutesProvider>
        </Suspense>
      </Provider>,
      successElem
    );
  }

  if ( tvElem ) {
    ReactDOM.render(
      <Provider store={store}>
        <Suspense fallback={null}>
          <TV />
        </Suspense>
      </Provider>,
      tvElem
    );
  }

  if ( videoModalElem ) {
    ReactDOM.render(
      <Provider store={store}>
        <Suspense fallback={null}>
          <VideoModal />
        </Suspense>
      </Provider>,
      videoModalElem
    );
  }

  if ( videosElem ) {
    ReactDOM.render(
      <Provider store={store}>
        <Suspense fallback={null}>
          <EppoRandomizationProvider>
            <Videos />
          </EppoRandomizationProvider>
        </Suspense>
      </Provider>,
      videosElem
    );
  }
});

document.addEventListener( 'turbolinks:before-cache', () => {
  const ids = [
    'account-stats-root',
    'analytics-pixel-root',
    'app-root',
    'app-sign-in-success-root',
    'auth-root',
    'belly-rewards-root',
    'category-root',
    'live-classes-root',
    'delivery-date-change-root',
    'favorite-collection-root',
    'footer-root',
    'forgot-password-root',
    'instructions-root',
    'print-e-gift-card-root',
    'megaphone-root',
    'newsletter-root',
    'order-status-root',
    'pre-cart-root',
    'product-admin-tools',
    'product-instructions-root',
    'recommended-products-root',
    'rewards-root',
    'save-rewards-root',
    'sbr-root',
    'shop-by-restaurant-root',
    'site-header-root',
    'sso-buttons-root',
    'state-root',
    'success-root',
    'tv-root',
    'video-modal-root',
    'videos-root',
  ];

  let elem = null;
  ids.forEach(( id ) => {
    elem = document.getElementById( id );

    // Only unmount if root element is present
    if ( elem ) {
      ReactDOM.unmountComponentAtNode( elem );
    }
  });

  // Classes
  let elems = [];
  const classes = [
    'order-root',
    'order-again-button-root',
    'resend-recipient-email-root',
    'suborder-options-root',
  ];

  classes.forEach(( className ) => {
    elems = document.getElementsByClassName( className );

    Array.from( elems ).forEach(( node ) => {
      ReactDOM.unmountComponentAtNode( node );
    });
  });
});
